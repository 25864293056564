// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-employers-js": () => import("./../../../src/pages/employers.js" /* webpackChunkName: "component---src-pages-employers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-v-1-js": () => import("./../../../src/pages/v1.js" /* webpackChunkName: "component---src-pages-v-1-js" */),
  "component---src-pages-v-2-js": () => import("./../../../src/pages/v2.js" /* webpackChunkName: "component---src-pages-v-2-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-pr-tips-js": () => import("./../../../src/templates/prTips.js" /* webpackChunkName: "component---src-templates-pr-tips-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/projectPage.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-redirect-with-replace-js": () => import("./../../../src/templates/redirectWithReplace.js" /* webpackChunkName: "component---src-templates-redirect-with-replace-js" */)
}

